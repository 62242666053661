import { useEffect, useState } from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import './AppInstallBadge.css';

const AppInstallBadge = () => {
  const [showBadge, setShowBadge] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        const getOffTime = localStorage.getItem("appInstallBadgeRemove");
        const offTime = new Date(getOffTime);
        const currentTime = new Date();
        const timeDiff = currentTime.getTime() - offTime.getTime();
        const minutesDiff = Math.floor(timeDiff / 60000);
        setShowBadge(minutesDiff > 5);
      } catch (error) {
      }
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const getDeviceInfo = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "iOS";
  }
  const removeBadge = () => {
    localStorage.setItem("appInstallBadgeRemove", new Date());
    setShowBadge(false);
  }
  return (
    window.innerWidth <= 1024 && showBadge && (localStorage.getItem("activateAppInstallBadge") && localStorage.getItem("activateAppInstallBadge") == 'true') ?
      <div className={`app-install-badge ${showBadge ? 'show' : 'hide'}`} style={{
        "backgroundColor": "#f8f9fa",
        "padding": "10px 10px",
        "display": "flex",
        "justifyContent": "space-between",
        "gap": "20px",
      }}>
        {getDeviceInfo() == 'Android' ?
          <a href="https://play.google.com/store/apps/details?id=io.eatiko.main" target="_blank" rel="noopener noreferrer">
            <img
              src={(localStorage.getItem("primary_cdn_orgin") ? localStorage.getItem("primary_cdn_orgin") : "https://eatiko.com") + "/assets/img/logos/wallet-icon.png"}
              alt="Download on Google Play"
              className="app-install-badge-icon"
              style={{
                height: '50px',
                width: 'auto',
                borderRadius: '0.5rem',
              }}
            />
          </a>
          :
          <a href="https://apps.apple.com/in/app/eatiko-food-delivery-app/id1527293841" target="_blank" rel="noopener noreferrer">
            <img
              src={(localStorage.getItem("primary_cdn_orgin") ? localStorage.getItem("primary_cdn_orgin") : "https://eatiko.com") + "/assets/img/logos/wallet-icon.png"}
              alt="Download on the App Store"
              className="app-install-badge-icon"
              style={{
                height: '50px',
                width: 'auto',
                borderRadius: '0.5rem',
              }}
            />
          </a>
        }
        <div className='d-flex flex-column justify-content-center'>
          <span
            style={{
              fontSize: window.innerWidth <= 320 ? '0.75rem' : '0.98rem',
              fontWeight: '600',
              whiteSpace: 'nowrap'
            }}>Eatiko Food & Delivery</span>
          <span
            style={{
              fontSize: window.innerWidth <= 320 ? '0.45rem' : '0.8rem',
              fontWeight: '400'
            }}>For best user experience</span>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <a
            style={{
              whiteSpace: 'nowrap',
              fontSize: window.innerWidth <= 320 ? '0.5rem' : '0.65rem',
              background: 'linear-gradient(90deg, rgb(38, 36, 36) 0%, rgb(38, 36, 36) 100%)',
              color: '#fafafa',
              padding: '0.25rem 0.5rem',
              borderRadius: '0.75rem',
              fontWeight: '500',
            }}
            href={getDeviceInfo() == 'Android' ? "https://play.google.com/store/apps/details?id=io.eatiko.main" : "https://apps.apple.com/in/app/eatiko-food-delivery-app/id1527293841"} target="_blank" rel="noopener noreferrer"
          >Download Now</a>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <span className='pt-1' onClick={() => removeBadge()}>
            <RiCloseCircleLine size={18} color='#121212' />
          </span>
        </div>
      </div>
      : <></>
  );
}

export default AppInstallBadge;
