import { API_URL } from "./website";

var host = window.location.host;
export const is_local = host.indexOf('localhost') > -1 || host.indexOf('192.168.') > -1 || host.indexOf('beta.eatiko.com') > -1 || host.indexOf('test.eatiko.com') > -1;

export const GET_SETTINGS_URL = API_URL + "/get-settings";
export const SEARCH_LOCATIONS_URL = API_URL + "/search-location";
export const GET_POPULAR_LOCATIONS_URL = API_URL + "/popular-geo-locations";
export const GET_LOCATION_MAPBOX_TOKEN_URL = API_URL + "/get-mapbox-token";
export const GET_PROMO_SLIDER_URL = API_URL + "/promo-slider";
export const GET_BILL_BOARDS_URL = API_URL + "/bill-boards";
export const GET_DELIVERY_RESTAURANTS_URL = API_URL + "/get-delivery-restaurants";
export const GET_DELIVERY_COIN_RESTAURANTS_URL = API_URL + "/get-delivery-coin-restaurants";
export const GET_HOMEPAGE_FEATUREDSTORES_URL = API_URL + "/home-featured-stores";
export const GET_HOMEPAGE_MOST_ORDERED_ITEMS_URL = API_URL + "/most-ordered-items";
export const GET_SELFPICKUP_RESTAURANTS_URL = API_URL + "/get-selfpickup-restaurants";
export const GET_RESTAURANT_INFO_URL = API_URL + "/get-restaurant-info";
export const GET_RESTAURANT_INFO_BY_ID_URL = API_URL + "/get-restaurant-info-by-id";
export const GET_RESTAURANT_ITEMS_URL = API_URL + "/get-restaurant-items";
export const APPLY_COUPON_URL = API_URL + "/apply-coupon";
export const CHECK_FIRST_ORDER_URL = API_URL + "/check-first-order";
export const LOGIN_USER_URL = API_URL + "/login";
export const LOGIN_USER_OTP_URL = API_URL + "/login-send-otp";
export const LOGIN_PHONE_URL = API_URL + "/login-phone";
export const CLUB_USER_PAYTM_PAYMENT_URL = API_URL + "/club/user/payment/paytm";
export const USER_SUBSCRIPTION_PLANS_URL = API_URL + "/customer/subscription-plans"
export const USER_DINE_IN_AMOUNT_URL = API_URL + "/customer/dine-out-payment"
export const USER_DINE_IN_PAYMENT_URL = API_URL + "/customer/dine-out-payment-status"
export const USER_CASHFREE_PAYMENT_URL = API_URL + "/payment/cashfree-react-native"
export const USER_CASHFREE_PAYMENT_STATUS_URL = API_URL + "/payment/cashfree-react-native-status"
export const CHECK_CURRENT_CITY_URL = API_URL + "/user-city-check"

export const CLUB_USER_COIN_PURCHASE_PAYTM_PAYMENT_URL = API_URL + "/club/user/payment/paytm";

export const REGISTER_USER_URL = API_URL + "/register";
export const GET_PAGES_URL = API_URL + "/get-pages";
export const GET_SINGLE_PAGE_URL = API_URL + "/get-single-page";
export const SEARCH_RESTAURANTS_URL = API_URL + "/search-restaurants";
export const GET_ADDRESSES_URL = API_URL + "/get-addresses";
export const SAVE_ADDRESS_URL = API_URL + "/save-address";
export const DELETE_ADDRESS_URL = API_URL + "/delete-address";
export const UPDATE_USER_INFO_URL = API_URL + "/update-user-info";
export const UPDATE_USER_INFO_FAILUR_URL = API_URL + "/update-user-info-failure";
export const DELETE_USER_ACCOUNT_URL = API_URL + "/delete-user-account";
export const UPDATE_USER_PROFILE_IMAGE_URL = API_URL + "/update-user-profile-image";
export const UPDATE_USER_PROFILE_DETAILS_URL = API_URL + "/update-user-profile-details";
export const CHANGE_USER_AVATAR_URL = API_URL + "/change-avatar";
export const PLACE_ORDER_URL = API_URL + "/place-order-1";
export const SET_DEFAULT_URL = API_URL + "/set-default-address";
export const GET_ORDERS_URL = API_URL + "/get-orders";
export const GET_PAYMENT_GATEWAYS_URL = API_URL + "/get-payment-gateways";
export const NOTIFICATION_TOKEN_URL = API_URL + "/save-notification-token";
export const SEND_OTP_URL = API_URL + "/send-otp";
export const VERIFY_OTP_URL = API_URL + "/verify-otp";
export const RAZORPAY_PAYMENT_URL = API_URL + "/payment/process-razor-pay";
export const PAYTM_PAYMENT_URL = API_URL + "/payment/paytm";
export const CASHFREE_ORDER_PAYMENT_URL = API_URL + "/payment/cashfree-order";
export const CASHFREE_ORDER_SUCCESS_PAYMENT_URL = API_URL + "/payment/cashfree-order-success";
export const UPDATE_CART_ITEMS_URL = API_URL + "/update-cart-items";
export const CLUB_STORE_PAYTM_PAYMENT_URL = API_URL + "/club/store/payment/paytm";

export const CHECK_USER_RUNNING_ORDER_URL = API_URL + "/check-running-order";
export const GET_ORDER_CANCEL_URL = API_URL + "/cancel-order";
export const GET_WALLET_TRANSACTIONS_URL = API_URL + "/get-wallet-transactions";
export const PURCHASE_WALLET_TRANSACTIONS_URL = API_URL + "/purchase-wallet-request";
export const PURCHASE_WALLET_TRANSACTION_STATUS_URL = API_URL + "/purchase-wallet-request-status";
export const PURCHASE_WALLET_PROCESS_PAYTM_URL = API_URL + "/user-wallet-request/payment/paytm";
export const CHECK_RESTAURANT_OPERATION_SERVICE_URL = API_URL + "/check-restaurant-operation-service";
export const GET_SINGLE_ITEM_URL = API_URL + "/get-single-item";
export const GET_CATEGORY_ITEMS_URL = API_URL + "/get-single-category-items";
export const GET_ALL_LANGUAGES_URL = API_URL + "/get-all-languages";
export const GET_SINGLE_LANGUAGE_DATA_URL = API_URL + "/get-single-language";
export const GET_SERVER_VERIFY_TOKEN_URL = API_URL + "/get-server-verify-token";
export const GET_ADDRESS_FROM_COORDINATES = API_URL + "/coordinate-to-address?is_sensor=1";
export const SEND_PASSWORD_RESET_EMAIL_URL = API_URL + "/send-password-reset-mail";
export const VERIFY_PASSWORD_RESET_OTP_URL = API_URL + "/verify-password-reset-otp";
export const CHANGE_USER_PASSWORD_URL = API_URL + "/change-user-password";
export const GET_RESTAURANTS_CATEGORIES_URL = API_URL + "/get-all-restaurants-categories";
export const GET_RESTAURANTS_REVIEWS_URL = API_URL + "/get-restaurant-reviews";
export const GET_FILTERED_RESTAURANTS_URL = API_URL + "/get-filtered-restaurants";
export const GET_RESTAURANTS_SLIDES_URL = API_URL + "/get-restaurant-category-slides";
export const GET_ALERTS_URL = API_URL + "/get-user-notifications";
export const MARK_ALL_NOTIFICATIONS_READ_URL = API_URL + "/mark-all-notifications-read";
export const MARK_ONE_NOTIFICATION_READ_URL = API_URL + "/mark-one-notification-read";
export const CHECK_CART_ITEMS_AVAILABILITY_URL = API_URL + "/check-cart-items-availability"+(is_local ? '?is_local=1' : '');

export const GET_REVIEWS_OF_STORE_URL = API_URL + "/get-store-reviews";
export const GET_SINGLE_ORDER_TO_BE_RATED = API_URL + "/single-ratable-order";
export const GET_RATABLE_ORDER_DETAILS_URL = API_URL + "/get-ratable-order-m";
export const ADD_RATING_URL = API_URL + "/rate-order";

/* Delivery URLs */
export const LOGIN_DELIVERY_USER_URL = API_URL + "/delivery/login";
export const UPDATE_DELIVERY_USER_INFO_URL = API_URL + "/delivery/update-user-info";
export const GET_DELIVERY_ORDERS_URL = API_URL + "/delivery/get-delivery-orders";
export const GET_SINGLE_DELIVERY_ORDER_URL = API_URL + "/delivery/get-single-delivery-order";
export const SEND_DELIVERY_GUY_GPS_LOCATION_URL = API_URL + "/delivery/set-delivery-guy-gps-location";
export const GET_DELIVERY_GUY_GPS_LOCATION_URL = API_URL + "/delivery/get-delivery-guy-gps-location";
export const ACCEPT_TO_DELIVER_URL = API_URL + "/delivery/accept-to-deliver";
export const PICKEDUP_ORDER_URL = API_URL + "/delivery/pickedup-order";
export const DELIVER_ORDER_URL = API_URL + "/delivery/deliver-order";

/* Restaurant URLs */

export const LOGIN_STORE_USER_URL = API_URL + "/store/login";
export const UPDATE_STORE_USER_INFO_URL = API_URL + "/store/dashboard";
export const FETCH_OWNED_USER_STORE_URL = API_URL + "/store/fetch-owned-stores";
export const GET_STORE_ITEMS_URL = API_URL + "/store/get-store-items";

export const VALIDATE_RESTAURANT_INPUT_URL = API_URL + "/register-restaurant-validation";

export const LOGIN_OTP_USER_URL = API_URL + "/login-otp-user";
export const REGISTER_OTP_USER_URL = API_URL + "/register-otp-user";
export const REGISTER_NEW_USER_URL = API_URL + "/register-new-user";
export const SEND_CUSTOM_OTP_URL = API_URL + "/custom-otp-user";

//Eatiko Club Routes
export const CLUB_CUSINES_URL = API_URL + "/club-cusines";
export const CLUB_PLANS_URL = API_URL + "/club-plans";

export const GET_CLUB_STORE_STORIES = API_URL + "/store/club/get-club-store-stories";
export const GET_CLUB_STORE_WALLET_URL = API_URL + "/store/club/get-club-restaurant-wallet";
export const GET_STORE_WALLET_TRANSACTION_URL = API_URL + "/store/club/dine-in-transactions";
export const GET_CLUB_STORE_WALLET_REQUEST_URL = API_URL + "/store/club/send-club-restaurant-wallet-request";
export const GET_CLUB_STORE_DETAILS_URL = API_URL + "/store/club/get-club-restaurant-details";
export const GET_CLUB_STORE_WALLET_REQUEST_STATUS_URL = API_URL + "/store/club/send-club-restaurant-wallet-request-status";
export const GET_CLUB_STORE_WALLET_REQUEST_HISTORY_URL = API_URL + "/store/club/get-club-restaurant-wallet-request-history";
export const GET_CLUB_STORE_BANNERS = API_URL + "/store/club/get-club-banners";
export const GET_RESTAURANT_PAYOUT_REPORT = API_URL + "/store/get-restaurant-new-payouts";
export const GET_RESTAURANT_PAYOUT_REPORT2 = API_URL + "/store/get-restaurant-new-payouts2";
export const GET_CLUB_STORE_MENUS = API_URL + "/store/club/get-menus";
export const GET_CLUB_STORE_WALLET_PAYTM_PAYMENT_URL = API_URL + "/store/club/dine-out-wallet/payment/paytm";

export const CITIES_URL = API_URL + "/fetch-cities";
export const DINE_OUTS_URL = API_URL + "/dine-outs";
export const LIKED_DINE_OUTS_URL = API_URL + "/user-liked-club-stores";
export const GET_YUMMY_SPOTTED_NEAR_URL = API_URL + "/yummy-spotteds-near";
export const GET_YUMMY_SPOTTED_SINGLE_URL = API_URL + "/yummy-spot-single";
export const GET_YUMMY_SPOTTED_SINGLE_LOG_URL = API_URL + "/yummy-spot-single-log";
export const STORE_YUMMY_SPOTTED_REVIEW_URL = API_URL + "/store-yummy-spot-review";
export const STORE_YUMMY_SPOTTED_LIKE_URL = API_URL + "/store-yummy-spot-like";
export const CATEGORYWISE_DINE_OUTS_URL = API_URL + "/dine-outs-categorywise";

export const SINGLE_DINE_INFO_URL = API_URL + "/single-dine-info";
export const DINE_PAYMENT_STATUS_URL = API_URL + "/user/club/dine-payment-status";
export const GET_DINE_REVIEWS_URL = API_URL + "/club-store-reviews";
export const GET_DINE_BANNER_URL = API_URL + "/club-dining-banner";
export const DINE_OUT_IN_BILLS_URL = API_URL + "/dine-outs-bills";
export const DINE_OUT_SINGLE_INFO_URL = API_URL + "/dine-out-single";

export const COMMENT_FOR_CLUB_STORE_URL = API_URL + "/user/club-comment";
export const COMMENTED_FOR_CLUB_STORE_URL = API_URL + "/user/check-club-comment";

export const LOGIN_OTP_CLUB_USER_URL = API_URL + "/club/store/custom-otp-user";
export const VERIFY_OTP_CLUB_USER_URL = API_URL + "/club/store/verify-otp-user";
export const UPDATE_CLUB_USER_INFO_URL = API_URL + "/store/club/dashboard";
export const GET_RESTAURANT_REVIEWS = API_URL + "/store/club/get-restaurant-reviews";

export const CLUB_USER_PLANS_URL = API_URL + "/club-user-plan";
export const CLUB_USER_PLAN_SUBSCRIBE_URL = API_URL + "/user/club/upgrade-subscriptions";
export const UPGRADE_CLUB_USER_PLAN_SUBSCRIBE_URL = API_URL + "/user/club/upgrade-plan";
export const CLUB_USER_CARDS_URL = API_URL + "/club-user-card-create";
export const USER_COIN_COMBO_PURCHASE_URL = API_URL + "/club-user-combo-coins";
export const USER_COIN_PURCHASE_URL = API_URL + "/user/club/user-coin-purchase";
export const CURRENT_COIN_RATE_URL = API_URL + "/get-current-coin-rate";
export const GET_CURRENT_COIN_RATE_URL = API_URL + "/current-coin-rate";
export const GET_USER_SUBSCRIBE_URL = API_URL + "/user/club-subscription";
export const SUBSCRIPTION_PLAN_FEATURES_URL = API_URL + "/club-user-plan-features"

export const DINE_PAYMENT_RESTAURANT_URL = API_URL + "/user/club/dine-out-payment";

export const SET_PAGE_USER_COUNT_URL = API_URL + "/set-page-user-count";
export const GET_FLASH_DEAL_ITEMS_URL = API_URL + "/get-flash-deal-items"+(is_local ? '?is_local=1' : '');
export const GET_FLASH_DEAL_USERS_URL = API_URL + "/get-flash-deal-users";

export const GET_OFFER_PERCENTAGE_URL = API_URL + "/get-offer-percentage-groups";
export const GET_OFFERS_LIST_URL = API_URL + "/get-offers-list";
export const GET_OFFER_PERCENTAGE_ITEMS_URL = API_URL + "/get-offer-percentage-items";
export const GET_OFFER_AMOUNT_URL = API_URL + "/get-offer-amount-groups";
export const GET_OFFER_AMOUNT_ITEMS_URL = API_URL + "/get-offer-amount-items";
export const GET_COUPON_RESTAURANT_URL = API_URL + "/get-coupon-restaurant-display";
export const GET_LOG_COUPON_RESTAURANT_URL = API_URL + "/get-log-coupon-restaurant-display";
export const GET_COUPON_ITEMS_URL = API_URL + "/get-coupon-items-display";
export const GET_COUPON_LOG_ITEMS_URL = API_URL + "/get-log-coupon-items-display";
export const GET_CITY_STORE_TYPES_URL = API_URL + "/get-city-store-types"+(is_local ? "?is_local=1" : '');
export const GET_COMING_SOON_URL = API_URL + "/get-coming-soon-items"+(is_local ? "?is_local=1" : '');
export const SET_COMING_SOON_NOTIFY_URL = API_URL + "/set-coming-soon-notify"+(is_local ? "?is_local=1" : '');
export const GET_AD_BANNER_IMAGE_URL = API_URL + "/get-ad-banner"+(is_local ? "?is_local=1" : '');
export const GET_YUMMY_ITEMS_URL = API_URL + "/get-yummy-items"+(is_local ? "?debug=true" : '');
export const GET_YUMMY_ITEMS_IFTAR_URL = API_URL + "/get-iftar-items"+(is_local ? "?debug=true" : '');
export const GET_ITEM_GROUP_URL = API_URL + "/get-item-groups"+(is_local ? "?debug=true" : '');
export const GET_LUNCH_BOX_RESTAURANT_URL = API_URL + "/get-lunch-restaurant";
export const GET_FRESH_LIST_ITEMS_URL = API_URL + "/get-fresh-list-items";

export const GET_FEEDBACK_QUESTIONS_URL = API_URL + "/get-feedback-types";
export const SAVE_FEEDBACK_ANSWERS_URL = API_URL + "/save-user-feedback";
export const SAVE_FEEDBACK_ANSWERS_LOGGED_URL = API_URL + "/save-user-feedback-logged";
export const SAVE_INACTIVE_USER_FEEDBACK_URL = API_URL + "/save-inactive-user-feedback";
export const GET_STATUS_OF_FREE_DELIVERY_URL = API_URL + "/get-status-of-free-delivery";
export const APPLY_OFFER_URL = API_URL + "/apply-offer";